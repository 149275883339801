<template>
  <div>
    <LabelAndMessage>
      <template #label>
        <IxRes>cultivation.labels.totalAreaOfSelectedCultivations</IxRes>:
      </template>
      <span>&nbsp;</span>
      <span>{{ sum }}</span>
    </LabelAndMessage>
    <HelpBox v-if="showHints">
      <IxRes>cultivation.hints.totalAreaOfSelectedCultivations</IxRes>
    </HelpBox>
    <LabelAndMessage>
      <template #label>
        <IxRes>cultivation.labels.averageCultivationArea</IxRes>:
      </template>
      <span>&nbsp;</span>
      <span>{{ average }}</span>
    </LabelAndMessage>
    <HelpBox v-if="showHints">
      <IxRes>cultivation.hints.averageCultivationArea</IxRes>
    </HelpBox>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import {sum, meanBy} from 'lodash'
import {mapUiFlags} from '@frs/helpers/ui'

import HelpBox from '@components/help/HelpBox'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    HelpBox,
    LabelAndMessage
  },
  computed: {
    ...mapUiFlags([
      'cultivation.showHints'
    ]),
    ...mapState('fieldRecordSystem/cultivationOverview', {
      selected: state => state.selected
    }),
    ...mapGetters('fieldRecordSystem/cultivationOverview', [
      'selectedCultivationData'
    ]),
    sum () {
      const areaSumInSquareMeters = sum(this.selectedCultivationData.map(cultivation => cultivation.area))
      return this.$i18n.format(areaSumInSquareMeters, 'hectare')
    },
    average () {
      return this.$i18n.format(meanBy(this.selectedCultivationData, 'area'), 'hectare')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
