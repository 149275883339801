<template>
  <div class="cultivation-statistics">
    <h3 v-if="$slots.title">
      <slot name="title" />
    </h3>
    <div class="overlay-container">
      <div v-if="noData" class="overlay">
        <div>
          <IxRes>cultivation.tooltips.noMainOrCatchCropSelected</IxRes>
        </div>
      </div>
      <DonutChart
        :data="chartData" :additional-options="additionalOptions"
        multidata @click="onElementClicked"
      />
    </div>
  </div>
</template>

<script>
import {groupBy, first} from 'lodash'

import DonutChart from '@components/charts/DonutChart'

import {format} from 'src/js/i18n/conversion'

export default {
  components: {
    DonutChart
  },
  props: {
    cultivations: {
      type: Array,
      required: true
    }
  },
  computed: {
    cultivationsByType () {
      return groupBy(this.cultivations, 'type')
    },
    mainCultivationData () {
      const mainLookup = groupBy(this.cultivationsByType['main'], 'label')

      return Object.values(mainLookup).map(cultivations => this.createChartElement(cultivations))
    },
    secondaryAndCatchCultivations () {
      const secondaryLookup = groupBy(this.cultivationsByType['secondary'], 'label')
      const catchLookup = groupBy(this.cultivationsByType['catch'], 'label')

      const secondaryCultivations = Object.values(secondaryLookup).map(cultivations => this.createChartElement(cultivations))
      const catchCultivations = Object.values(catchLookup).map(cultivations => this.createChartElement(cultivations))

      return [
        ...secondaryCultivations,
        ...catchCultivations
      ]
    },
    noData () {
      return this.mainCultivationData.length === 0 && this.secondaryAndCatchCultivations.length === 0
    },
    chartData  () {
      if (this.noData) {
        return [[{label: '', value: 1, color: '#888'}]]
      }

      return [
        this.mainCultivationData,
        this.secondaryAndCatchCultivations
      ]
    },
    additionalOptions () {
      return {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              const label = data.labels[tooltipItem.datasetIndex][tooltipItem.index]
              let sum = 0
              data.datasets[tooltipItem.datasetIndex].data.forEach(val => {
                sum += val
              })

              const mainLabel = Array.isArray(label) ? label[0] : label
              const additionalLabels = Array.isArray(label) ? label.slice(1) : []
              return [mainLabel, `${format(value, 'hectare-sparse')}`, `${format(value / sum, 'percent')}`, ...additionalLabels]
            }
          }
        }
      }
    }
  },
  methods: {
    onElementClicked (event) {
      this.$emit('click', first(event.map(x => x)))
    },
    createChartElement (cultivations) {
      const firstCultivation = first(cultivations)
      return {
        ...firstCultivation,
        value: cultivations.reduce((a, b) => a + b.area, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cultivation-statistics {
  display: flex;
  flex-direction: column;

  &:last-child {
    align-self: center;
  }

  .total-area {
    font-size: 1.5em;
  }

  .overlay-container{
    height: 100%;
    width: 100%;
    position: relative;

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;

      div {
        background-color: #ffffffcc;
        width: auto;
        height: auto;
        margin: 30px;
        padding: 5px;
        border-radius: 5px;
      }
    }
  }
}
</style>
