<template lang="html">
  <canvas ref="canvas" :width="width" :height="height" />
</template>

<script>
import ChartMixin from './ChartMixin'

export default {
  mixins: [
    ChartMixin
  ],
  computed: {
    options () {
      return {
        type: 'doughnut'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
