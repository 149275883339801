var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('LabelAndMessage',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("label")]},proxy:true}],null,true)},[_c('SimpleTable',{staticClass:"simple-table",attrs:{"columns":_vm.columns,"data":_vm.tableData,"localize-headers":""},scopedSlots:_vm._u([{key:"color",fn:function(ref){
var row = ref.row;
return [_c('div',{style:({backgroundColor: row.color, width: '12px', height: '16px', border: '1px solid black', borderRadius: '3px'})})]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.type))])]}},{key:"label",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.label))])]}},{key:"area",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.$i18n.format(row.area, 'hectare')))])]}},{key:"percent",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.$i18n.format(row.area / _vm.areaSum, 'percent')))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }