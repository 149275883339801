<template>
  <div>
    <LabelAndMessage>
      <template #label>
        <slot name="label" />
      </template>

      <SimpleTable
        class="simple-table"
        :columns="columns" :data="tableData"
        localize-headers
      >
        <template #color="{row}">
          <div :style="{backgroundColor: row.color, width: '12px', height: '16px', border: '1px solid black', borderRadius: '3px'}" />
        </template>
        <template #type="{row}">
          <span>{{ row.type }}</span>
        </template>
        <template #label="{row}">
          <span>{{ row.label }}</span>
        </template>
        <template #area="{row}">
          <span>{{ $i18n.format(row.area, 'hectare') }}</span>
        </template>
        <template #percent="{row}">
          <span>{{ $i18n.format(row.area / areaSum, 'percent') }}</span>
        </template>
      </SimpleTable>
    </LabelAndMessage>
  </div>
</template>

<script>
import {orderBy, sumBy, groupBy, head} from 'lodash'
import SimpleTable from '@components/table/SimpleTable'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage,
    SimpleTable
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    columns () {
      return {
        color: '',
        type: 'cultivation.table.labels.type',
        label: 'cultivation.table.labels.name',
        area: 'cultivation.table.labels.area',
        percent: 'cultivation.table.labels.percent'
      }
    },
    tableData () {
      const groupedData = groupBy(this.data, 'label')
      const data = Object.keys(groupedData).map(key => {
        const values = groupedData[key]
        const firstValue = head(values)

        return {
          label: firstValue.label,
          type: firstValue.typeShortcut,
          color: firstValue.color,
          area: sumBy(values, 'area')
        }
      })

      return orderBy(data, ['area', 'label'], ['desc', 'asc'])
    },
    areaSum () {
      return sumBy(this.data, 'area')
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-table {
  ::v-deep td:first-child {
    vertical-align: middle;
    text-align: -webkit-center;
  }
}
</style>
