<template>
  <div class="chart-container">
    <div>
      <CultivationDonutChart :cultivations="filteredCultivations">
        <template #title>
          <slot name="second-chart-title" />
        </template>
      </CultivationDonutChart>
      <HelpBox v-if="showHints">
        <slot name="second-chart-hint" />
      </HelpBox>
    </div>
  </div>
</template>

<script>
import CultivationDonutChart from './CultivationDonutChart'
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    HelpBox,
    CultivationDonutChart
  },
  props: {
    filteredCultivations: {
      type: Array,
      required: true
    },
    showHints: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-container {
 ::v-deep .alert {
   margin-top: 4px;
 }
}
</style>
