<template>
  <div class="cultivation-summary">
    <div class="summary-header">
      <h3>
        <IxRes>cultivation.titles.overviewCultivations</IxRes>
      </h3>
      <BsCheckbox v-model="showHints" inline>
        <IxRes>cultivation.hints.showChartHint</IxRes>
      </BsCheckbox>
    </div>
    <CultivationStatistics
      v-if="selected.length === 0" :harvest-year="year"
      :org-unit-id="orgUnitId"
      :extern-show-hints="showHints"
    />
    <CultivationsStatistics v-else />

    <CultivationDonuts
      :filtered-cultivations="selectedCultivationData"
      :show-hints="showHints"
    >
      <template #second-chart-title>
        <IxRes v-if="selected.length === 0">cultivation.labels.TotalCultivatedArea_Chart</IxRes>
        <IxRes v-else>cultivation.labels.SelectedCultivatedArea_Chart</IxRes>
      </template>
      <template #second-chart-hint>
        <IxRes>cultivation.hints.SelectedCultivatedArea_Chart</IxRes>
      </template>
    </CultivationDonuts>

    <CultivationTable :data="selectedCultivationData.filter(data => data.typeShortcut === 'HF')">
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationType_main</IxRes>
      </template>
    </CultivationTable>

    <CultivationTable :data="selectedCultivationData.filter(data => data.typeShortcut === 'ZF' || data.typeShortcut === 'ZW')">
      <template #label>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationType_secondary_catch</IxRes>
      </template>
    </CultivationTable>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
import {mapUiFlags} from '@frs/helpers/ui'

import BsCheckbox from '@components/bootstrap/BsCheckbox'

import CultivationsStatistics from './CultivationsStatistics'
import CultivationStatistics from './CultivationStatistics'
import CultivationDonuts from './CultivationDonuts'
import CultivationTable from '@frs/components/cultivation-overview/summary/CultivationTable'

export default {
  components: {
    CultivationTable,
    BsCheckbox,
    CultivationsStatistics,
    CultivationStatistics,
    CultivationDonuts
  },
  props: {
    year: {
      type: Number,
      required: true
    },
    orgUnitId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapUiFlags([
      'cultivation.showHints'
    ]),
    ...mapState('fieldRecordSystem/cultivationOverview', {
      selected: state => state.selected
    }),
    ...mapGetters('fieldRecordSystem/cultivationOverview', [
      'selectedCultivationData',
      'cultivationData'
    ])
  },
  methods: {
    ...mapMutations('fieldRecordSystem/cultivationOverview', [
      'clearSelected',
      'toggleCultivation'
    ])
  }
}
</script>

<style lang="scss" scoped>
.cultivation-summary {
  padding: 0 8px;

  .summary-header {
    margin-bottom: 10px;
  }
}
</style>
