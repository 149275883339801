import { render, staticRenderFns } from "./CultivationsStatistics.vue?vue&type=template&id=3f842abc&scoped=true&"
import script from "./CultivationsStatistics.vue?vue&type=script&lang=js&"
export * from "./CultivationsStatistics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f842abc",
  null
  
)

export default component.exports